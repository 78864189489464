<template>
  <div
    ref="loginPageIncentiveRef"
    class="login-page__incentive"
  >
    <div class="login-page__incentive-title">
      {{ titleTxt }}
    </div>
    <div class="login-page__incentive-content">
      <!-- 券利益点 -->
      <div
        v-if="['coupons', 'promotion'].includes(incentiveType)"
        class="login-page__incentive-coupons fsp-element"
        :class="{ 'login-page__coupons-single': couponData.length == 1 }"
      >
        <div
          v-for="(item, index) in couponData.slice(0, 3)"
          :key="index"
          class="incentive-coupons__item"
          :style="{
            backgroundImage: index == 2 && couponData.length > 3
              ? `url(${imgs['coupon-item-more']})`
              : `url(${imgs?.[`coupon-item-${
                item.benifitType == 0
                  ? 'shipping'
                  : 'off'
              }_${couponData.length > 2 ? 3 : couponData.length}`]})`
          }"
        >
          <div
            v-if="([0, 1].includes(index) || (index == 2 && couponData.length == 3)) && isFreshText"
            :class="[
              'incentive-coupons__item-coupon',
              item.benifitType == 0 ? 'incentive-coupons__item-shipping' : ''
            ]"
            :style="{ width: couponData.length > 2 ? '2.6667rem' : couponData.length == 2 ? '4.0533rem' : '5.6rem' }"
          >
            <SAdapterText
              :min-size="couponData.length > 2 ? 16 : 14"
              :lines="couponData.length > 2 ? 2 : 1"
              :text="item.benifitType == 0 ? langText.SHEIN_KEY_PWA_36047 : `${item.maxValue} ${langText.SHEIN_KEY_PWA_36074}`"
            />
          </div>
          <div
            v-if="index == 2 && couponData.length > 3"
            class="incentive-coupons__item-other"
          >
            <p class="item-other__num">
              + {{ couponData.length - 2 }}
            </p>
            <p class="item-other__txt">
              {{ langText.SHEIN_KEY_PWA_36073 }}
            </p>
          </div>
        </div>
      </div>
      <!-- 免邮 -->
      <img
        v-if="incentiveType == 'freeShipping'"
        class="login-page__incentive-shipping fsp-element"
        :src="imgs.free_cart"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({ name: 'LoginPageIncentive' })
</script>
<script setup>
import { computed, nextTick, ref, onMounted, onUnmounted } from 'vue'
import { useStore } from '@login/hooks/utils'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import useIncentiveHook from '@login/hooks/useIncentiveHook.js'

const store = useStore()

const props = defineProps({
  type: { type: String, default: '' },
})

const { imgs, couponData, singleCoupon, incentiveType, LoginButtonPoint, isCouponFreeShipping } = useIncentiveHook()
const langText = computed(() => store.state.login.langText)
const isFreshText = ref(false)
const loginPageIncentiveRef = ref(null)
const isContinue = computed(() => {
  return props.type == 'continue'
})

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // 执行元素可见时的操作
        nextTick(() => isFreshText.value = true)
      } else {
        // 执行元素不可见时的操作
        nextTick(() => isFreshText.value = false)
      }
    })
  })
  if (loginPageIncentiveRef.value) {
    observer.observe(loginPageIncentiveRef.value)
  }
})

onUnmounted(() => {
  if (loginPageIncentiveRef.value) {
    observer.unobserve(loginPageIncentiveRef.value)
  }
})

const titleTxt = computed(() => {
  if (incentiveType.value == 'freeShipping' && !isCouponFreeShipping.value) {
    return isContinue.value
      ? langText.value.SHEIN_KEY_PWA_36045
      : langText.value.SHEIN_KEY_PWA_36043
  }
  if (incentiveType.value == 'promotion') {
    return isContinue.value
      ? langText.value.SHEIN_KEY_PWA_36046
      : langText.value.SHEIN_KEY_PWA_36044
  }
  if (incentiveType.value == 'coupons' && LoginButtonPoint.value?.from == 'cart') {
    return isContinue.value
      ? langText.value.SHEIN_KEY_PWA_36049
      : langText.value.SHEIN_KEY_PWA_36048
  }
  if (singleCoupon.value) {
    if (couponData.value?.[0]?.benifitType == 0) {
      return isContinue.value
        ? langText.value.SHEIN_KEY_PWA_30112
        : langText.value.SHEIN_KEY_PWA_36043
    }
    return isContinue.value
      ? langText.value.SHEIN_KEY_PWA_36042
      : langText.value.SHEIN_KEY_PWA_36040
  }
  return isContinue.value
    ? langText.value.SHEIN_KEY_PWA_36041
    : langText.value.SHEIN_KEY_PWA_36039
})
</script>

<style lang="less">
.login-page {
  &__incentive {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__incentive-title {
    color: #222;
    text-align: center;
    font-size: 20px;
    font-weight: 860;
    margin-bottom: .4267rem;
  }
  &__incentive-shipping {
    width: 3.7333rem;
    height: 2.2133rem;
  }
  &__incentive-promotion {
    width: 10rem;
    height: 3.1467rem;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: flex-end;
    .incentive-promotion__wrap {
      margin: 0 auto;
      max-width: 4rem;
      font-size: 54px;
      margin-bottom: 1rem;
      font-weight: 1000;
      line-height: 1.2;
      color: #3A2614;
      text-align: center;
    }
  }
  &__incentive-coupons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.3867rem;
    height: 2.2933rem;
    padding: .2133rem .1067rem .16rem .2133rem;
    border-radius: 10px;
    background: linear-gradient(0deg, #F6451E 12.35%, #FFB3A6 100%);
    .incentive-coupons__item {
      flex: 1;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: .1067rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .incentive-coupons__item-coupon {
      height: 1.6rem;
      font-size: 30px;
      font-weight: 700;
      line-height: normal;
      color: #F6451E;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .incentive-coupons__item-shipping {
      color: #169E00;
    }
    .incentive-coupons__item-other {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      color: #F6451E;
      text-align: center;
      .item-other__txt {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  &__coupons-single {
    width: 6.5333rem;
  }
}
</style>
