<template>
  <div
    v-if="newPrivacyAbt.type == 'yes' && newUIStates.type == 'signup'"
    class="page-login__com-privacy"
  >
    <kr-privacy-common
      v-if="SiteUID == 'mkr'"
      :language="langText"
      from="register"
      @change-handle="changeHandle"
    />
    <s-checkbox 
      v-else
      v-model="checkboxPrivacy"
      name="privacy"
      class="j-privacy-checkbox"
      @change="handlePrivacy"
    >
      <div
        @click="handleNewPrivacyClick"
        v-html="combineNewPrivacyLanguage({ language: langText, abt: 'yes', from: 'signup', location: `${type ? type : 'email'}_register` })"
      ></div>
    </s-checkbox>
  </div>
</template>

<script>
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { defineComponent } from 'vue'
import { mapState, mapMutations } from 'vuex'
import { combineNewPrivacyLanguage, handleNewPrivacyClick } from '../../util'
import { setSessionStorage } from '@shein/common-function'
import analysisIndex from '../../analysis/index'
const { sensorsSend } = analysisIndex
const { SiteUID } = gbCommonInfo
import KrPrivacyCommon from 'public/src/pages/components/KrPrivacyCommon/index.vue'

export default defineComponent({
  name: 'LoginPrivacy',
  components: { KrPrivacyCommon, SCheckbox },
  props: {
    type: { type: String, default: '' },
  },
  data: () => ({
    SiteUID,
    checkboxPrivacy: false
  }),
  computed: {
    ...mapState('login', ['langText', 'newPrivacyAbt', 'checkboxPrivacyValue', 'newUIStates']),
  },
  watch: {
    checkboxPrivacyValue(val) {
      this.checkboxPrivacy = val
    },
    checkboxPrivacy(val){
      setSessionStorage({ key: 'checkboxPrivacy', value: val })
    }
  },
  methods: {
    ...mapMutations('login', ['setCheckboxPrivacy']),
    combineNewPrivacyLanguage,
    handleNewPrivacyClick,
    handlePrivacy () {
      this.$emit('privacy-change', this.checkboxPrivacy, 'privacy')
      sensorsSend('2-8-11', { register_scene: 1 })
    },
    changeHandle ({ privacy, subscrip }) {
      setSessionStorage({ key: 'checkboxPrivacy', value: privacy })
      this.$emit('privacy-change', privacy)
      this.$emit('subscrip-change', subscrip)
    }
  }
})
</script>

<style lang="less">
.page-login__com-privacy {
  margin-top: 11px;
  a {
    color: @sui_color_link;
    text-decoration: none;
  }
  .sui-checkbox__label-text {
    color: #767676;
    line-height: 1.1;
    .font-dpr(24px) !important;/* stylelint-disable-line declaration-no-important */
    .padding-l(12px);
  }
}
</style>
