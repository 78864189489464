<template>
  <!-- 弱合规隐私政策 -->
  <div class="page__login-weekPrivacy">
    <p
      v-show="type == 'login'"
      class="third-privacy-tip "
      @click="handleNewPrivacyClick"
      v-html="combineNewPrivacyLanguage({ language: langText, abt: 'no', from: 'third', type, location })"
    ></p>
    <p
      v-show="type == 'signup'"
      class="third-privacy-tip "
      @click="handleNewPrivacyClick"
      v-html="combineNewPrivacyLanguage({ language: langText, abt: 'no', from: 'third', type, location })"
    ></p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { combineNewPrivacyLanguage, handleNewPrivacyClick } from '../../../util'

export default defineComponent({
  name: 'WeekPrivacy',
  props: {
    type: { type: String, default: '' },
    location: { type: String, default: '' }
  },
  computed: {
    ...mapState('login', ['langText']),
  },
  methods: {
    combineNewPrivacyLanguage,
    handleNewPrivacyClick,
  },
})
</script>

<style lang="less">
.page__login-weekPrivacy {
  text-align: center;
  padding: 12px 0 0 0;
  color: @sui_color_gray_light1;
  a {
    color: @sui_color_link;
    text-decoration: none;
  }
}
</style>
