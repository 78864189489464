<template>
  <div class="page-login__privacy-bottom">
    <Privacy
      :type="type"
      @privacy-change="handlePrivacy"
      @subscrip-change="handleSubscrib"
    />
    <SubScrib
      v-if="!noSubscribe"
      :type="type"
      @subscrip-change="handleSubscrib"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Privacy from './Privacy.vue'
import SubScrib from './SubScrib.vue'
export default defineComponent({
  name: 'PrivacyBottom',
  components: { Privacy, SubScrib },
  props: {
    type: { type: String, default: '' },
    noSubscribe: { type: Boolean, default: false }
  },
  methods: {
    handlePrivacy (v) {
      this.$emit('privacy-change', v, 'privacy')
      // sensorsSend('2-8-11', { register_scene: 1 })
    },
    handleSubscrib (v) {
      this.$emit('subscrip-change', v, 'subscrib')
    }
  },
})
</script>

<style lang="less">
.page-login__privacy-bottom {
    .privacy-item {
        display: flex;
        align-items: flex-start;
        margin-top: 12px;
        label {
            .margin-r(8px);
        }
        a {
            color: @sui_color_link;
            text-decoration: none;
        }
        .sui-checkbox__label-text {
            color: #767676;
            line-height: 1.1;
            .font-dpr(24px);
        }
        .mshe-checkbox {
            // border-color: #CCCCCC;
            width: 20px;
            height: 20px;
        }
    }
}
</style>
