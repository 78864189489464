<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
  >
    <rect
      x="0.81875"
      y="4.83267"
      width="9.3625"
      height="7.02917"
      stroke="#198055"
      stroke-width="1.1375"
    />
    <mask
      id="path-2-inside-1_1_8695"
      fill="white"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.66795 5.04181V3.68056C7.66795 2.48317 6.69728 1.5125 5.49989 1.5125C4.30251 1.5125 3.33184 2.48317 3.33184 3.68056V5.04181H2.19434V3.68056C2.19434 1.85495 3.67428 0.375 5.49989 0.375C7.3255 0.375 8.80545 1.85495 8.80545 3.68056V5.04181H7.66795Z"
      />
    </mask>
    <path
      d="M7.66795 5.04181H6.79295V5.91681H7.66795V5.04181ZM3.33184 5.04181V5.91681H4.20684V5.04181H3.33184ZM2.19434 5.04181H1.31934V5.91681H2.19434V5.04181ZM8.80545 5.04181V5.91681H9.68045V5.04181H8.80545ZM8.54295 5.04181V3.68056H6.79295V5.04181H8.54295ZM8.54295 3.68056C8.54295 1.99992 7.18053 0.6375 5.49989 0.6375V2.3875C6.21403 2.3875 6.79295 2.96642 6.79295 3.68056H8.54295ZM5.49989 0.6375C3.81926 0.6375 2.45684 1.99992 2.45684 3.68056H4.20684C4.20684 2.96642 4.78576 2.3875 5.49989 2.3875V0.6375ZM2.45684 3.68056V5.04181H4.20684V3.68056H2.45684ZM2.19434 5.91681H3.33184V4.16681H2.19434V5.91681ZM1.31934 3.68056V5.04181H3.06934V3.68056H1.31934ZM5.49989 -0.5C3.19103 -0.5 1.31934 1.3717 1.31934 3.68056H3.06934C3.06934 2.3382 4.15753 1.25 5.49989 1.25V-0.5ZM9.68045 3.68056C9.68045 1.3717 7.80875 -0.5 5.49989 -0.5V1.25C6.84225 1.25 7.93045 2.3382 7.93045 3.68056H9.68045ZM9.68045 5.04181V3.68056H7.93045V5.04181H9.68045ZM7.66795 5.91681H8.80545V4.16681H7.66795V5.91681Z"
      fill="#198055"
      mask="url(#path-2-inside-1_1_8695)"
    />
    <rect
      x="5.30566"
      y="6.59741"
      width="0.875"
      height="3.5"
      fill="#198055"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LockIcon'
})
</script>
