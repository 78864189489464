<template>
  <div class="page__login-encrypt-tips">
    <LockIcon class="page__login-lock"/>
    {{ langText?.SHEIN_KEY_PWA_31997 }}
  </div>
</template>

<script setup>
import LockIcon from '@login/components/Icon/LockIcon.vue'
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'

const store = useStore()

const langText = computed(() => store.state.login.langText)


;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ProtectedTipsIndex',
})
</script>

<style lang="less" scoped>
.page__login-encrypt-tips{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #198055;
  .page__login-lock{
    margin-right: 2px;
    transform: translateY(-1px);
  }
}
</style>