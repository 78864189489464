if (typeof window !== 'undefined') {
  // 对外提供的调用方法
  // Vue.prototype.$evt = window._GB_LoginEventCenter_
  window.signupTime = 0 // 注册时长
  window.signupTimeTag = null
  
  const isAsComponent = !/\/user(\/auth)?\/login(\/)?$/.test(location.pathname) && !/\/auth\/login(\/)?$/.test(location.pathname) // 是否在登录页面,否则认为作为组件展示
  window.isLoginPageAsCompnent = isAsComponent // 全局变量，用来在登录成功后判断跳转方式
}

// Vue.prototype.$toast = Toast
