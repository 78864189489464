import { getCurrentInstance } from 'vue'

// 访问vuex
export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$store
}
// 访问router
export const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$router
}
// 访问route
export const useRoute = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$route
}

// export const useToast = () => {
//   const vm = getCurrentInstance()
//   if (!vm) throw new Error('must be called in setup')
//   return vm.proxy.$toast
// }

export const useGeetest = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.GTInstance
}

export const useEventBus = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return vm.proxy.$evt
}
