import { computed, ref } from 'vue'
import { useStore } from '@login/hooks/utils'
import { benifitImgs } from '@login/utils/index.js'

export default function useIncentiveHook () {
  const store = useStore()

  const imgs = ref(benifitImgs)
  const LoginButtonPoint = computed(() => store.state.login.LoginButtonPoint)
  const couponData = computed(() => LoginButtonPoint.value?.couponData)
  const singleCoupon = computed(() => {
    return LoginButtonPoint.value?.type == 'coupons' && (couponData.value?.length == 1)
  })
  const isCouponFreeShipping = computed(() => {
    return singleCoupon.value && (couponData.value?.[0]?.benifitType == 0)
  })
  const incentiveType = computed(() => {
    const commonAbt = store.state.login.commonAbt
    if (commonAbt?.LoginPageIncentive?.benefitShowType != 'on') return ''
    if (isCouponFreeShipping.value) return 'freeShipping'
    return LoginButtonPoint.value?.type || ''
  })

  return { imgs, LoginButtonPoint, couponData, singleCoupon, incentiveType, isCouponFreeShipping }
}
